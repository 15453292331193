import Vue from 'vue'
import Vuex from 'vuex'
import changeLogsApi from '@/api/v1/change_logs'

Vue.use(Vuex)

const state = () => ({
  changeLogs: [],
  mappingColumnNames: [
    // Invoices
    { object: 'invoice', label: 'invoice_date', value: '청구일자' },
    { object: 'invoice', label: 'invoice_type', value: '청구서종류' },
    { object: 'invoice', label: 'invoice_title', value: '청구서명칭' },
    { object: 'invoice', label: 'total_amount', value: '금액' },
    { object: 'invoice', label: 'status', value: '청구서상태' },
    { object: 'invoice', label: 'deposit_status', value: '입금상태' },
    { object: 'invoice', label: 'total_tax', value: '부가세' },
    { object: 'invoice', label: 'external_url', value: '링크' },
    { object: 'invoice', label: 'payment_type', value: '결제방법' },
    { object: 'invoice', label: 'account_number', value: '입금계좌' },
    { object: 'invoice', label: 'memo', value: '비고' },
    { object: 'invoice', label: 'issue_evidence', value: '증빙발행' },
    { object: 'invoice', label: 'issue_tax_invoice', value: '영수/청구' },
    { object: 'invoice', label: 'user_id', value: '사용자' },
    { object: 'invoice', label: 'active', value: 'active' },

    // Expenses
    { object: 'expense', label: 'expense_date', value: '지출일자' },
    { object: 'expense', label: 'expense_type', value: '종류' },
    { object: 'expense', label: 'payment_type', value: '결제구분' },
    { object: 'expense', label: 'description', value: '비고' },
    { object: 'expense', label: 'invoice_id', value: '청구서ID' },
    { object: 'expense', label: 'is_billable', value: '청구여부' },
    { object: 'expense', label: 'external_url', value: '링크' },
    { object: 'expense', label: 'amount', value: '금액' },
    { object: 'expense', label: 'charge_vat', value: '부가세' },
    { object: 'expense', label: 'user_id', value: '사용자' },
    { object: 'expense', label: 'active', value: 'active' },

    // 기여도
    { object: 'commission', label: 'invoice_id', value: '청구서ID' },
    { object: 'commission', label: 'user_position', value: '직급' },
    { object: 'commission', label: 'invoice_total_pool_ratio', value: '비중' },
    { object: 'commission', label: 'user_id', value: '변호사' },
    { object: 'commission', label: 'active', value: 'active' },

    // 타임시트
    { object: 'timesheet_entry', label: 'invoice_name', value: '청구서명칭' },
    { object: 'timesheet_entry', label: 'work_date', value: '업무일자' },
    { object: 'timesheet_entry', label: 'work_type', value: '업무구분' },
    { object: 'timesheet_entry', label: 'performance_lawyer', value: '변호사' },
    { object: 'timesheet_entry', label: 'work_hours', value: '업무시간' },
    { object: 'timesheet_entry', label: 'billable_hours', value: '청구시간' },
    { object: 'timesheet_entry', label: 'description', value: '업무내용' },
    { object: 'timesheet_entry', label: 'user_position', value: '직급' },
    { object: 'timesheet_entry', label: 'active', value: 'active' },

    // 청구서매칭
    { object: 'deposit_to_invoices', label: 'attributed_amount', value: '입금금액' },
  ],

})

const getters = {
  getColumnName: state => (object, column) => {
    let found = state.mappingColumnNames.find(el => el.object === object && el.label === column)
    return (found) ? found.value : ''
  },
}

const actions = {
  getChangeLogs ({ commit }, investmentId) {
    changeLogsApi.getChangeLogs(investmentId).then(resp => {
      commit('setChangeLogs', resp.data)
    })
  },
  filterChangeLogs ({ commit }, filterParams) {
    changeLogsApi.getChangeLogs(filterParams.drilldownInvestmentId, filterParams.filterText, filterParams.customFieldTemplateId).then(resp => {
      commit('setChangeLogs', resp.data)
    })
  },
  createComment ({ dispatch }, comment) {
    let data = new FormData()
    data.append('investment_id', comment.investmentId)
    data.append('value', comment.value)
    data.append('comment_file', comment.attachedFile)
    return new Promise(resolve => {
      changeLogsApi.createComment(data).then(resp => {
        resolve(resp)
        dispatch('getChangeLogs', comment.investmentId)
      })
    })
  },
  updateComment ({ dispatch, commit }, comment) {
    return new Promise(resolve => {
      changeLogsApi.updateComment(comment).then(resp => {
        resolve(resp)
        commit('updateChangeLog', resp)
        dispatch('getChangeLogs', comment.investmentId)
      })
    })
  },
  deleteComment ({ dispatch }, comment) {
    return new Promise(resolve => {
      changeLogsApi.deleteComment(comment.id).then(resp => {
        resolve(resp)
        dispatch('getChangeLogs', comment.investmentId)
      })
    })
  },
}

const mutations = {
  setChangeLogs (state, fromApi) {
    fromApi.forEach(log => log.editing = false)
    state.changeLogs = fromApi
  },
  updateChangeLog (state, log) {
    let index = state.changeLogs.findIndex(slog => slog.id === log.id)
    if (index) {
      state.changeLogs.splice(index, 1, log)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
