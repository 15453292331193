<template>
  <div class='flex flex-row justify-start'>
    <div class='border p-4 rounded flex-grow-0'>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('invoiceTitle') }}</label>
        <input type='text' class='form-input w-48' v-model='invoice_title'>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('billingDate') }}</label>
        <el-date-picker
          v-model='invoice_date'
          value-format='yyyy-MM-dd'
          type='date'
          :placeholder="$t('fromDate')"
          class='invoice-date-picker'>
        </el-date-picker>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('invoiceType') }}</label>
        <select
          v-model='invoice_type'
          class='form-select w-48'>
          <option value=''></option>
          <option
            v-for='selectableInvoiceType in selectableInvoiceTypes'
            :key='`invoice-types-dropdown-${selectableInvoiceType}`'
            :value='selectableInvoiceType'>
            {{selectableInvoiceType}}
          </option>
        <option :disabled='!isAdminOrBilliing' value='관리자보정'>관리자보정</option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('link') }}</label>
        <input type='text' class='form-input w-48' v-model='external_url'>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('paymentMethod') }}</label>
        <v-select
          v-model='selectedPaymentTypes'
          :options='selectableInvoicePaymentTypes'
          label='paymentType'
          :close-on-select='false'
          multiple
          class='w-48'>
            <template v-slot:option='option'>
              {{ option.paymentType }}
            </template>
        </v-select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('depositAccount') }}</label>
        <select
          v-model='account_number'
          class='form-select w-48'>
          <option value=''></option>
          <option
            v-for='account in selectableAccountNumbers'
            :key='`account-numbers-dropdown-${account.account_number}`'
            :value='account.account_number'>
            {{account.bank}} {{account.account_number}}
          </option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('issueEvidence') }}</label>
        <select
          v-model='issue_evidence'
          class='form-select w-48'>
          <option value=''></option>
          <option
            v-for='issueEvidence in selectableIssueEvidences'
            :key='`issue-evidence-dropdown-${issueEvidence}`'
            :value='issueEvidence'>
            {{issueEvidence}}
          </option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('receiptInvoice') }}</label>
        <select
          v-model='issue_tax_invoice'
          class='form-select w-48'>
          <option value=''></option>
          <option
            v-for='issueTaxInvoice in selectableIssueTaxInvoice'
            :key='`issue-tax-invoice-dropdown-${issueTaxInvoice}`'
            :value='issueTaxInvoice'>
            {{issueTaxInvoice}}
          </option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('notesAdditionalRequests') }}</label>
        <input type='text' class='form-input w-48' v-model='memo'>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('invoiceStatus') }}</label>
        <select
          v-model='status'
          class='form-select w-48'>
          <option value=''></option>
          <option
            v-for='state in selectableStatuses'
            :key='`invoice-status-dropdown-${state}`'
            :disabled='isDisabledStatus(state)'>
            {{state}}
          </option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('depositStatus') }}</label>
        <select
          v-model='deposit_status'
          class='form-select w-48'>
          <option value=''></option>
          <option
            v-for='depositStatus in selectableDepositStatuses'
            :key='`invoice-deposit-status-dropdown-${depositStatus}`'>
            {{depositStatus}}
          </option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='inline-block w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('issueDate') }}</label>
        <el-date-picker
          v-model='issue_date'
          value-format='yyyy-MM-dd'
          type='date'
          placeholder='Issue date'
          class='invoice-date-picker'>
        </el-date-picker>
      </div>
      <div class='flex flex-row justify-start gap-0 items-center my-2'>
        <label class='w-32 uppercase font-semibold text-sm text-gray-600'>{{ $t('representative') }}</label>
        <user-search
          :user-ids='issue_by'
          :user-names='issueNames'
          class='w-48'
          @update-selected-user='updateInvoiceIssueBy($event)' />
      </div>
    </div>
    <div class='flex-grow bg-gray-100 p-4 relative'>
      <div class='flex flex-row justify-end items-center gap-x-4 mb-2'>
        <label class='w-32 text-right uppercase font-semibold text-sm text-gray-600'>{{ $t('totalDepositWoTaxAmountKrw') }}</label>
        <div
          class='w-40 text-right pr-3'
          style='height: 42px; line-height: 42px;'>
          {{ numberStyle(subTotalAmount) }}
        </div>
      </div>
      <div class='flex flex-row justify-end items-center gap-x-4 mb-2'>
        <label class='w-32 text-right uppercase font-semibold text-sm text-gray-600'>{{ $t('vatKrw') }}</label>
        <div v-if='use_calculated_total'
          class='w-40 text-right pr-3'
          style='height: 42px; line-height: 42px;'>
          {{ numberStyle(total_tax) }}
        </div>
        <vue-numeric v-else
          v-model='total_tax'
          output-type='number'
          class='form-input w-40 text-right'
          :minus='true'
          :empty-value='0'
          separator=','>
        </vue-numeric>
      </div>
      <div class='flex flex-row justify-end items-center gap-x-4 text-gray-900 border-t mt-4 pt-4'>
        <label class='w-32 text-right uppercase font-semibold text-sm'>{{ $t('totalBilledAmountKrw') }}</label>
        <div v-if='use_calculated_total'
          class='w-40 text-right pr-3'
          style='height: 42px; line-height: 42px;'>
          {{ numberStyle(total_amount) }}
        </div>
        <vue-numeric v-else
          v-model='total_amount'
          output-type='number'
          class='form-input w-40 text-right'
          :minus='true'
          :empty-value='0'
          separator=','>
        </vue-numeric>
      </div>
      <div class='flex flex-row justify-end items-center gap-x-4 mt-4 pr-2'>
        <label class='standard-transition text-sm hover:bg-gray-200 cursor-pointer p-1 rounded-md text-right text-gray-600 hover:text-gray-900 flex flex-row justify-end gap-x-0.5 items-center'>
          <input type='checkbox' class='form-checkbox mr-2' v-model='use_calculated_total'>
          <span>{{ $t('autoCalculationBelowAmount') }}</span>
        </label>
      </div>
      <div v-if='canViewInvoiceCreator'
        class='p-4 absolute bottom-0 right-0'>
        <button
          class='standard-transition border hover:border-blue-300 hover:shadow-md hover:bg-white py-3 px-8 text-xs uppercase rounded-md'
          @click='openPdfCreator'>
          {{ $t('makePdfInvoice') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import InvoicesHelpers from '@/utils/invoices-helpers.js'
import UserSearch from '@/components/UserSearch.vue'

export default {
  name: 'CaseInvoiceDetailsOverview',
  components: {
    UserSearch,
  },
  data () {
    return {
      originalTax: 0,
      originalTotal: 0,
      issueNames: '',
    }
  },
  watch: {
    'editingInvoice.id': {
      handler: function (newVal) {
        if (newVal) {
          this.originalTax = this.total_tax
          this.originalTotal = this.total_amount
        }
      },
      immediate: true,
    },
    'use_calculated_total': {
      handler: function (newVal) {
        if (newVal) {
          this.total_tax = parseFloat(this.calculatedTotalTax)
          this.total_amount = parseFloat(this.calculatedTotal)
        } else {
          this.total_tax = this.originalTax
          this.total_amount = this.originalTotal
        }
      },
    },
    calculatedTotal: {
      handler: function (newVal) {
        if (this.use_calculated_total) {
          this.total_amount = parseFloat(newVal)
        }
      }
    },
    calculatedTotalTax: {
      handler: function (newVal) {
        if (this.use_calculated_total) {
          this.total_tax = parseFloat(newVal)
        }
      }
    },
    total_amount: {
      handler: function (newVal) {
        if (!this.use_calculated_total) {
          this.total_tax = Math.floor(parseFloat(newVal) / 11)
        }
      }
    },
    'editingInvoice.issue_by': {
      handler: function (newVal) {
        if (newVal) {
          this.issueNames = this.getUserNameFromId(newVal)
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapFields('invoices', [
      'editingInvoice.invoice_title',
      'editingInvoice.invoice_date',
      'editingInvoice.invoice_type',
      'editingInvoice.external_url',
      'editingInvoice.total_tax',
      'editingInvoice.total_amount',
      'editingInvoice.use_calculated_total',
      'editingInvoice.status',
      'editingInvoice.payment_type',
      'editingInvoice.account_number',
      'editingInvoice.memo',
      'editingInvoice.deposit_status',
      'editingInvoice.issue_evidence',
      'editingInvoice.issue_tax_invoice',
      'editingInvoice.issue_date',
      'editingInvoice.issue_by',
    ]),
    ...mapState('invoices', [
      'invoices',
      'editingInvoice',
    ]),
    ...mapState('expenses', [
      'expenses',
    ]),
    ...mapState('timesheetEntries', [
      'timesheetEntries',
    ]),
    ...mapGetters('users', [
      'isAdmin',
      'isBilling',
      'isBbridgeAdmin',
      'getUserNameFromId',
    ]),
    isAdminOrBilliing () {
      return this.isAdmin || this.isBilling
    },
    selectableStatuses () {
      return InvoicesHelpers.selectableStatuses()
    },
    selectableDepositStatuses () {
      return InvoicesHelpers.selectableDepositStatuses()
    },
    selectableInvoiceTypes () {
      return InvoicesHelpers.selectableInvoiceTypes()
    },
    selectableInvoicePaymentTypes () {
      return InvoicesHelpers.selectableInvoicePaymentTypes()
    },
    selectableAccountNumbers () {
      return InvoicesHelpers.selectableAccountNumbers()
    },
    selectableIssueEvidences () {
      return InvoicesHelpers.selectableIssueEvidences()
    },
    selectableIssueTaxInvoice () {
      return InvoicesHelpers.selectableIssueTaxInvoice()
    },
    selectedPaymentTypes: {
      get () {
        if (this.payment_type) {
          return this.payment_type.split(',')
        } else {
          return []
        }
      },
      set (paymentTypes) {
        this.payment_type = paymentTypes.join(',')
      }
    },
    canViewInvoiceCreator () {
      return this.isBbridgeAdmin ||
             this.isAdmin ||
             this.isBilling
    },
    subTotalAmount () {
      return this.total_amount - this.total_tax
    },
    calculatedTotal () {
      return this.calculatedTotalExpenses + this.calculatedTotalTimeCost
    },
    calculatedTotalExpenses () {
      return this.expenses.reduce((sum, expense) => {
        if (expense.investment_id === this.editingInvoice.investment_id &&
            expense.invoice_id    === this.editingInvoice.id &&
            expense.is_billable) {
          return sum + parseFloat(expense.amount)
        } else {
          return sum
        }
      }, 0)
    },
    calculatedTotalTimeCost () {
      return this.timesheetEntries.reduce((sum, entry) => {
        if (entry.investment_id === this.editingInvoice.investment_id &&
            entry.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(entry.rate * entry.billable_hours)
        } else {
          return sum
        }
      }, 0)
    },
    calculatedTotalTax () {
      let totalTimeCoseTax = this.timesheetEntries.reduce((sum, entry) => {
        if (entry.investment_id === this.editingInvoice.investment_id &&
            entry.invoice_id === this.editingInvoice.id) {
          if (entry.charge_vat) {
            return sum + parseFloat(entry.rate * entry.billable_hours * 0.1)
          } else {
            return sum
          }
        } else {
          return sum
        }
      }, 0)
      let totalExpenseTax = this.expenses.reduce((sum, expense) => {
        if (expense.investment_id === this.editingInvoice.investment_id &&
            expense.invoice_id === this.editingInvoice.id) {
          if (expense.charge_vat && expense.is_billable) {
            return sum + parseFloat(expense.tax_amount)
          } else {
            return sum
          }
        } else {
          return sum
        }
      }, 0)
      return (totalTimeCoseTax + totalExpenseTax) ? Math.floor(totalTimeCoseTax + totalExpenseTax) : 0
    },
  },
  methods: {
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    isDisabledStatus (selectableStatus) {
      let canSeeAllStatuses = this.isBbridgeAdmin || this.isAdmin ||this.isBilling
      return !canSeeAllStatuses && ['청구완료', '발행완료'].includes(selectableStatus)
    },
    openPdfCreator() {
      let invoiceIndex = this.invoices.findIndex(invoice => invoice.id === this.editingInvoice.id) + 1
      let route = this.$router.resolve({
        name: 'InvoicePdfCreator',
        query: {
          investment_id: this.editingInvoice.investment_id,
          invoice_id: this.editingInvoice.id,
          invoice_index: invoiceIndex,
        }
      })
      window.open(route.href, '_blank')
    },
    updateInvoiceIssueBy(userIdAndName) {
      this.editingInvoice.issue_by = userIdAndName.user_id
    },
  },
}
</script>