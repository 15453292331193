<template>
  <div v-if='drilldownCanViewBilling'>
    <div class='flex flex-row justify-between items-center'>
      <button v-if='listMode'
        class='px-6 py-3 bg-gray-900 border rounded-md border-transparent  text-white opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-white flex flex-row justify-center items-center gap-x-1'
        @click='addInvoice({}, true)'>
        <plus-circle-icon/>
        <span class='text-xs uppercase'>{{ $t('newInvoice') }}</span>
      </button>
      <button v-if='listModeNAdmin'
        class='px-6 py-3 bg-gray-900 border rounded-md border-transparent  text-white opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-white flex flex-row justify-center items-center gap-x-1'
        @click='makeZeroDepositToInvoice'>
        <span class='text-xs uppercase'>0원 청구서 생성</span>
      </button>
    </div>
    <div class='overflow-x-auto w-full'>
      <table v-if='listMode'
        class='table-fixed text-sm mt-8 min-w-full'>
        <thead v-if='caseHasInvoices'>
          <tr class='uppercase font-semibold whitespace-no-wrap'>
            <th class='px-2 py-2 w-16 text-left'>ID</th>
            <th class='px-2 py-2 w-40 text-left' @click='clickInvoiceDateSort'>
              {{ $t('billingDate') }}
              <component class='inline-block' v-if='showInvoiceDateSort' :is='toggleSortingIcon'/>
            </th>
            <th class='px-2 py-2 w-32 text-left'>{{ $t('invoiceTitle') }}</th>
            <th class='px-2 py-2 w-32 text-left'>{{ $t('invoiceType') }}</th>
            <th class='px-2 py-2 w-32 text-left'>{{ $t('paymentMethod') }}</th>
            <th class='px-2 py-2 w-32 text-left'>{{ $t('depositAccount') }}</th>
            <th class='px-2 py-2 w-32 text-center'>{{ $t('contribution') }}</th>
            <th class='px-2 py-2 w-48 border-r text-right'>{{ $t('billableAmountKrw') }}</th>
            <th class='px-2 py-2 w-40 text-left' @click='clickDepositDateSort'>
              {{ $t('depositDate') }}
              <component class='inline-block' v-if='showDepositDateSort' :is='toggleSortingIcon'/>
            </th>
            <th class='px-2 py-2 w-48 border-r text-right'>{{ $t('depositAmoutKrw') }}</th>
            <th class='px-2 py-2 w-16 text-left'>{{ $t('link') }}</th>
            <th class='px-2 py-2 w-32 text-left'>{{ $t('invoiceStatus') }}</th>
            <th class='px-2 py-2 w-32 text-left'>{{ $t('depositStatus') }}</th>
            <th class='px-2 py-2 w-32'></th>
          </tr>
        </thead>
        <tbody v-if='caseHasInvoices'>
          <case-invoice-row
            v-for='invoice in sortingResults'
            :key='`case-invoices-${invoice.id}`'
            :invoice='invoice'
            class='border-b border-t'
            @edit:invoice='switchToEditMode' />
          <tr class='uppercase font-semibold'>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class='px-2 py-2'>{{ $t('total') }}</td>
            <td class='px-2 py-2 text-right border-r'>{{totalAmount}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan='9'
              class='text-center p-8 bg-gray-100'>
              {{ $t('noInvoiceData') }}
            </td>
          </tr>
        </tbody>
      </table>
      <case-invoice-details v-else @cancel:invoice='switchToListMode' />
    </div>
  </div>
  <no-billing-access v-else />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PlusCircleIcon, SortAscendingIcon, SortDescendingIcon } from '@vue-hero-icons/outline'
import numbro             from 'numbro'
import CaseInvoiceRow     from '@/components/invoices/CaseInvoiceRow.vue'
import CaseInvoiceDetails from '@/components/invoices/CaseInvoiceDetails.vue'
import NoBillingAccess    from '@/views/dashboard/NoBillingAccess.vue'
import InvoicesHelpers    from '@/utils/invoices-helpers.js'

import dayjs from 'dayjs'

export default {
  name: 'CaseInvoices',
  components: {
    CaseInvoiceDetails,
    CaseInvoiceRow,
    PlusCircleIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    NoBillingAccess,
  },
  data () {
    return {
      mode: 'list',
      currentSorting: 'invoice_date',
      invoiceDateSort: 'desc',
      depositDateSort: 'desc',
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0 && this.drilldownCanViewBilling) {
          this.switchToListMode()
          this.updateInvoiceList()
        }
      },
      immediate: true
    },
    drilldownCanViewBilling: { // have to watch incase somsetimes
      handler: function (newVal) {
        if (newVal) {
          this.updateInvoiceList()
        }
      },
    },
  },
  computed: {
    ...mapState('invoices', [
      'invoices',
      'newInvoice',
    ]),
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownCanViewBilling',
    ]),
    ...mapState('expenses', [
      'createNewInvoiceFromExpenses',
    ]),
    ...mapGetters('users', [
      'isAdmin',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapGetters('expenses', [
      'hasSelectedExpenseIds',
      'firstSelectedExpenseDescription',
      'firstSelectedExpenseInvoiceType',
    ]),
    listMode () {
      return this.mode === 'list'
    },
    hasZeroDepositToInvoice () {
      return this.invoices.filter(invoice => invoice.invoice_type === '관리자보정').length > 0
    },
    listModeNAdmin () {
      return this.mode === 'list' && this.isAdmin && !this.hasZeroDepositToInvoice
    },
    totalAmount () {
      let sum = 0
      if (this.invoices.length > 0) {
        sum = this.invoices.map(invoice => invoice.total_amount).reduce((a,b) => (a+b))
      }
      return numbro(sum).format({ thousandSeparated: true, mantissa: 0 })
    },
    caseHasInvoices () {
      return this.invoices.length > 0
    },
    toggleSortingIcon () {
      if (this.ascInvoiceDate || this.ascDepositDate) {
        return 'sort-ascending-icon'
      } else if (this.descInvoiceDate || this.descDepositDate) {
        return 'sort-descending-icon'
      } else {
        return ''
      }
    },
    ascInvoiceDate () {
      return this.invoiceDateSort === 'asc' && this.currentSorting === 'invoice_date'
    },
    ascDepositDate () {
      return this.depositDateSort === 'asc' && this.currentSorting === 'total_paid_date'
    },
    descInvoiceDate () {
      return this.invoiceDateSort === 'desc' && this.currentSorting === 'invoice_date'
    },
    descDepositDate () {
      return this.depositDateSort === 'desc' && this.currentSorting === 'total_paid_date'
    },
    showInvoiceDateSort () {
      return (this.ascInvoiceDate || this.descInvoiceDate)
    },
    showDepositDateSort () {
      return (this.ascDepositDate || this.descDepositDate)
    },
    sortingResults () {
      if (this.ascInvoiceDate) {
        return [...this.invoices].sort((a, b) => {
          let a_invoice_date = a.invoice_date ? a.invoice_date : ''
          let b_invoice_date = b.invoice_date ? b.invoice_date : ''
          return a_invoice_date.localeCompare(b_invoice_date)
        })
      } else if (this.descInvoiceDate) {
        return [...this.invoices].sort((a, b) => {
          let a_invoice_date = a.invoice_date ? a.invoice_date : ''
          let b_invoice_date = b.invoice_date ? b.invoice_date : ''
          return b_invoice_date.localeCompare(a_invoice_date)
        })
      } else if (this.ascDepositDate) {
        return [...this.invoices].sort((a, b) => a.total_paid_date.localeCompare(b.total_paid_date))
      } else if (this.descDepositDate) {
        return [...this.invoices].sort((a, b) => b.total_paid_date.localeCompare(a.total_paid_date))
      } else {
        return this.invoices
      }
    },
  },
  methods: {
    ...mapActions('invoices', [
      'caseInvoiceDetailsOpen',
      'createInvoice',
      'getInvoices',
      'getSingleInvoice',
    ]),
    ...mapActions('casePerformanceReports', [
      'postDepositToInvoice',
    ]),
    ...mapMutations('expenses', [
      'setCreateNewInvoiceFromExpenses',
    ]),
    addInvoice (opts, toggleAllExpenses=false) {
      let invoice = InvoicesHelpers.newInvoice()
      Object.assign(invoice, opts)
      invoice.investment_id = this.drilldownInvestmentId
      this.createInvoice(invoice).then(savedInvoice => {
        savedInvoice.toggleAllExpenses = toggleAllExpenses // select all expense on create
        this.switchToEditMode(savedInvoice)
      })
      this.setCreateNewInvoiceFromExpenses(false)
    },
    switchToEditMode (invoice) {
      this.mode = 'edit'
      this.caseInvoiceDetailsOpen(invoice)
    },
    switchToListMode () {
      this.mode = 'list'
    },
    updateInvoiceList () {
      let queryParamInvoiceId = this.$route.query.invoice_id
      if (queryParamInvoiceId && this.$route.name === 'DrilldownContainer') {
        this.getSingleInvoice(parseInt(queryParamInvoiceId)).then(resp => this.switchToEditMode(resp))
      } else {
        this.getInvoices({
          investment_id: this.drilldownInvestmentId,
          start_date:    dayjs('2010-01-01').format('YYYY-MM-DD'),
          end_date:      dayjs('2099-12-31').format('YYYY-MM-DD')
        })
      }
    },
    makeZeroDepositToInvoice () {
      this.$confirm("0원 청구서 및 입금내역을 생성하시겠습니까?", 'Warning', {
        confirmButtonText: this.$t('confirmSave'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.postDepositToInvoice([{investment_id: this.drilldownInvestmentId}]).then(() => {
          this.getInvoices({
            investment_id: this.drilldownInvestmentId,
            start_date:    dayjs('2010-01-01').format('YYYY-MM-DD'),
            end_date:      dayjs('2099-12-31').format('YYYY-MM-DD')
          })
          this.$message({
            type: 'success',
            message: this.$t('createEventSuccess')
          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: this.$t('error')
          })
        })
      }).catch(() => {
      })
    },
    clickInvoiceDateSort () {
      this.currentSorting = 'invoice_date'
      if (this.invoiceDateSort === 'asc') {
        this.invoiceDateSort = 'desc'
      } else {
        this.invoiceDateSort = 'asc'
      }
    },
    clickDepositDateSort () {
      this.currentSorting = 'total_paid_date'
      if (this.depositDateSort === 'asc') {
        this.depositDateSort = 'desc'
      } else {
        this.depositDateSort = 'asc'
      }
    },
  },
  mounted () {
    if (this.createNewInvoiceFromExpenses && this.hasSelectedExpenseIds) {
      this.addInvoice({invoice_title: this.firstSelectedExpenseDescription, invoice_type: this.firstSelectedExpenseInvoiceType})
    }
  }
}
</script>
