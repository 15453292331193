<template>
  <div class='relative entity-search h-12'>
    <vue-autosuggest
      class='absolute top-0 left-0 w-64 h-10 z-10'
      v-model='entitySearchText'
      ref='entitySearch'
      :suggestions='filteredOptions'
      :get-suggestion-value='suggestionValue'
      :should-render-suggestions='shouldShowIsh'
      :input-props="{id:'autosuggest__input',ref:'inputTextbox',placeholder:'회사명',class:'border px-4 py-1 rounded-md w-full'}"
      @input='onInputChange'
      @blur='updateIfEmpty'
      @closed='updateIfEmpty'
      @selected='selectHandler'>
      <template slot-scope="{suggestion}">
        <span
          class='text-gray-600 my-suggestion-item whitespace-nowrap'
          @mousedown.prevent='onSuggestionMouseDown(suggestion.item)'>
          {{suggestion.item.entity_name}}
        </span>
      </template>
      <template slot='after-suggestions'>
        <div class='px-2 py-2 text-center text-xs' v-if='emptySearchString'>{{emptyMessage}}</div>
        <button class='px-2 py-2 text-left text-xs add-entity bg-gray-100 w-full' v-else-if='noExactMatch'>
          {{entitySearchText}}
          <span class='inline-block opacity-75 ml-2'>({{ $t('createNewEntityButtonText', [""]) }})</span>
        </button>
      </template>
    </vue-autosuggest>
    <portal to='modals'>
      <entity-data-edit v-if='addNewEntity'
        :display-in-modal='true'
        @done-editing='doneEditing' />
    </portal>
  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
import { VueAutosuggest } from 'vue-autosuggest'
import debounce           from 'debounce'
import EntityDataEdit from '@/views/entities/EntityDataEdit.vue'
import EventBus       from '@/utils/event-bus'

export default {
  name: 'EntitySearch',
  components: {
    EntityDataEdit,
    VueAutosuggest,
  },
  props: [
    'entityId',
    'entityName',
    'blockCreateEntity'
  ],
  data () {
    return {
      filteredOptions: [],
      entitySearchText: '',
      selectedEntityId: 0,
      addNewEntity: false,
      isComposing: false,
    }
  },
  watch: {
    entityName: {
      handler: function (newVal) {
        if (newVal) {
          this.entitySearchText = newVal
        } else {
          this.entitySearchText = ''
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState([
      'selectedSuggest',
    ]),
    emptyMessage () {
      return `please search by name or hit enter to create`
    },
    emptySearchString () {
      return this.entitySearchText.length === 0 || (this.entitySearchText.trim() === '' && this.hasNoResults)
    },
    noExactMatch () {
      return !this.blockCreateEntity &&
             !this.emptySearchString &&
              this.filteredOptions.findIndex(entity => entity.entity_name === this.entitySearchText) === -1
    },
    hasNoResults () {
      return this.filteredOptions.length == 0 || this.filteredOptions[0].data.length == 0
    },
    showEmptyMessage () {
      return this.emptySearchString && this.hasNoResults
    },
    entityNameText () {
      return (this.entityName) ? `${this.entityName}` : ''
    },
    entitySelectText () {
      return this.$t('selectClient')
    }
  },
  methods: {
    ...mapActions('entities', [
      'createEntity',
      'searchEntitiesByName',
      'newEntitySetName',
    ]),
    ...mapMutations([
      'setShowPopover',
      'setSelectedSuggest',
    ]),
    addEntity () {
      // NOTE: apple,firefox korean will not work on first click as mouseevents are broken
      this.setShowPopover(false)
      this.$store.dispatch('modalOpen', {height: 60, width: 60})
      this.newEntitySetName(this.entitySearchText)
      this.addNewEntity = true
    },
    doneEditing (resp) {
      this.addNewEntity = false
      this.updateSelectedEntity(resp)
      this.$store.dispatch('modalClose')
    },
    closeSelectEntity () {
      this.setShowPopover(false)
      this.setSelectedSuggest('')
    },
    openSelect () {
      this.setShowPopover(true)
      this.setSelectedSuggest('entity')
    },
    suggestionValue (suggestion) {
      return suggestion.item.entity_name
    },
    searchTextByName (text) {
      this.searchEntitiesByName(text)
    },
    async onInputChange (text) {
      if (this.isComposing) return
      
      let filteredData = []
      if (text) {
        filteredData = await this.searchEntitiesByName(text)
      } else {
        filteredData = []
      }
      this.filteredOptions = [{
        data: filteredData
      }]
    },
    selectHandler (selected) {
      // Prevent selection during IME composition
      if (this.isComposing) return
      
      if (selected === null) {
        this.addEntity()
      } else if (selected) {
        this.entitySearchText = selected.item.entity_name
        this.updateSelectedEntity(selected.item)
      }
    },
    onSuggestionMouseDown(item) {
      if (this.isComposing) {
        setTimeout(() => {
          this.selectHandler({ item })
        }, 0);
      } else {
        this.selectHandler({ item })
      }
    },
    shouldShowIsh (size, loading) {
      return  size >= 0 && !loading
    },
    updateSelectedEntity (entity) {
      this.$emit('update-selected-entity', {
        entityName: entity.entity_name,
        entityId: entity.id,
      })
      this.closeSelectEntity()
    },
    updateIfEmpty () {
      if (!this.entitySearchText) {
        this.updateSelectedEntity({entity_name: '', id: 0})
      }
    }
  },
  created () {
    this.searchTextByName = debounce(this.searchTextByName, 300)
  },
  mounted () {
    this.$refs.entitySearch.$refs.inputTextbox.addEventListener('keyup', ({key}) => {
      if (key === 'Escape') {
        this.entitySearchText = this.entityName
        this.closeSelectEntity()
      }
    })

    // Add IME composition event handlers
    this.$refs.entitySearch.$refs.inputTextbox.addEventListener('compositionstart', () => {
      this.isComposing = true
    })
    this.$refs.entitySearch.$refs.inputTextbox.addEventListener('compositionend', () => {
      this.isComposing = false
      this.onInputChange(this.entitySearchText)
    })

    // Add keydown handler for selection during IME composition
    this.$refs.entitySearch.$refs.inputTextbox.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && this.isComposing) {
        // Force selection of first item if available
        if (this.filteredOptions[0]?.data?.length > 0) {
          const firstItem = this.filteredOptions[0].data[0]
          this.entitySearchText = firstItem.entity_name
          this.updateSelectedEntity(firstItem)
        }
      }
    })

    EventBus.$on('modalClose', () => {
      this.addNewEntity = false
    })
  },
  beforeDestroy () {
    EventBus.$off('modalClose')
  },
}
</script>
